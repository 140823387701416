 

.form-switch.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3rem + 0.75rem);
    border-radius: 4rem;
}

.form-check-label{
    margin-top: 8px;
    font-weight: bold;
    margin-right: 10px;
}

.map-shown #mobileForm {
    z-index: 1;
}

.map-shown .TopBar{
    z-index: 1;
}