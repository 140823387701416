.property-section{
   margin-top: 1rem;
   margin-bottom: 1rem;
}

.menuitem.active{
    background-color: #fff;
    color: #C5A05F;
    border-top: 1px solid #C5A05F;
}

.menuitem {
    display: block;
    border-right: 1px solid #fff;
    color: #000;
}

.image-text *{
   text-shadow: 2px 2px 4px rgba(66, 68, 90, 1);
}

.property-section h4 {
    text-align: center;
    border-bottom: 1px solid #C5A05F;
    margin-bottom: 20px;
    padding-bottom: 20px;
    margin-top: 40px;
    color: #C5A05F;
    font-size: 22px;
}

@media screen and (min-width: 1000px) {
    .property-page-title{
        background-color: rgba(197, 160, 95, 0.65);
        padding: 1.5rem;
    }
}

.property-page-title{
    text-transform: capitalize;
}   