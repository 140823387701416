.text-shadow{
   text-shadow: 1px 1px 2px black;
}

 

*{
   font-family: 'Montserrat', sans-serif !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

 