

$input-focus-width: 0;
$primary: #C5A05F;
$secondary: #C5A05F;
$table-active-bg: rgba(228, 228, 228, 0.5);
 
$table-bg: fff;
$table-striped-bg: rgba(228, 228, 228, 0.5);
$table-striped-color: #000;

$headings-font-family: 'Montserrat', sans-serif;
$btn-border-radius: 0px;

// Import Bootstrap and its default variables

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import '~bootstrap/scss/bootstrap.scss';
 