/* Hide default radio button */
.custom-radio input[type="radio"] {
    display: none;
}

/* Custom checkbox styles */
.custom-radio label {
    display: inline-block;
    padding-left: 35px;
    position: relative;
    cursor: pointer;
}

.custom-radio label:before,
.custom-radio label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

.custom-radio label:before {
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    background: #fff;
}

.custom-radio label:after {
    width: 12px;
        height: 12px;
        background: #C5A05F;
        opacity: 0;
        left: 3px;
        top: 3px;
}

/* Checked state */
.custom-radio input[type="radio"]:checked+label:after {
    opacity: 1;
}