.table-wrapper {
    position: relative;
    overflow: hidden;
}

.fade-out-table {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
}

.amenities tr:nth-of-type(odd)>tr div{
    background-color:rgba(228, 228, 228, 0.5) !important;
}

.rates-table tr:nth-of-type(even){
    background-color: rgba(228, 228, 228, 0.5) !important;
}

 
 

.table-striped > tbody > tr:nth-of-type(odd) > *
/* .amenities tr:nth-child(odd) {
    position: relative;
}

.amenities tr:nth-child(odd):before{
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    transform: translateX(-100%);
} */

.bullet-item::before {
    content: '•';
    display: inline-block;
    margin-right: 8px;
 
}