 .swiper-slide {
     transition: 0.5s;
 }

 
.view-all-photos {
    position: absolute;
    top: 3rem;
    z-index: 9;
    right: 3rem;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 9;
    padding: 5px 12px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    background: rgba(255, 255, 255, 0.9);
}

 .swiper-button-next.bg,
 .swiper-button-prev.bg {
     height: 100%;
     background: #00000015;
     top: 22px;
     bottom: 50px;
     width: 70px;
 }

@media screen and (max-width: 768px) {
    .swiper-button-next.bg,
        .swiper-button-prev.bg {

            background: transparent;

        }
}


.swiper-button-next.bg {
    right: 0;
}
 
.swiper-button-prev.bg {
    left: 0;
}