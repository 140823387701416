.bg-primary.wrapper-breadcrumb a {
    color: #fff;
}

.bg-secondary.wrapper-breadcrumb a {
    color: #fff;
}

.tw-bg-primary_dark.wrapper-breadcrumb a {
    color: #fff;
}