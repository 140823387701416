 
.availabilityCalendar table td {
    border: none;

}

 .availabilityCalendar table td :hover{
     border: none !important;
 }

  .availabilityCalendar .CalendarDay__blocked_out_of_range:hover {
     border: none !important;
 }

   .availabilityCalendar .CalendarDay__default:hover {
     border: none !important;
 }


 .calendar-arrows{
    color: #C5A05F;
    display: inline-flex;
    border: 1px solid #C5A05F;
    width: 22px;
    height: 22px;
    border-radius:100%;
    justify-content: center;
    align-items: center;
 }

 .DayPickerNavigation {
        display: flex;
            width: 95%;
            justify-content: space-between;
            top: 16px;
            margin: 0px 25px;
     /* border: 1px solid #ccc; */
 }

 .CalendarDay__selected_span {
     background: #e9e9e9 !important;
     color: black;
 }

 .CalendarDay__selected,
 .CalendarDay__selected:active,
 .CalendarDay__selected:hover {
     background: transparent;
 }

 div.CalendarDay__selected {
    border: 1px solid #ccc !important;
 }

 