.Filters {}

.svgFilters {
    display: inline-block;
    height: 14px;
    width: 14px;
    fill: currentColor;
    margin-left: 10px
}

.filter-switch {
    position: relative;
}

.filter-switch:after {
    position: absolute;

    font-size: 28px;
}

.opened-filter:after {
    content: "-";
    display: inline-block;
    color: #c5a05f;
    top: -13px;
    left: 4px;
}

.closed-filter:after {
    content: "+";
    display: inline-block;
    color: #fff;
    left: 4px;
    top: -8px;
}

/* Styles for your div */
.Filters {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    padding-bottom: 10px;
    /* Add your other styles here like sm:tw-h-screen, xl:tw-pr-4, etc. */
}

/* Styling the scrollbar track (background) */
.Filters::-webkit-scrollbar-track {
    background-color: #F5F5F5;
    border-radius: 10px;
    /* Optional: Adds rounded corners to the track */
}

/* Styling the scrollbar handle (thumb) */
.Filters::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;

    /* Optional: Adds rounded corners to the thumb */
    &:hover {
        background-color: #555;
        /* Change color when hovering over the scrollbar thumb */
    }
}

/* Optional: Style the scrollbar buttons (up & down or left & right arrows) */
.Filters::-webkit-scrollbar-button {
    display: none;
    /* This hides the buttons. Remove this rule if you want to style the buttons instead of hiding them. */
}

/* Optional: Set the width of the scrollbar. Adjust as needed. */
.Filters::-webkit-scrollbar {
    width: 7px;
}

.highlightOption {
    background: transparent !important;
    color: #000 !important;
}

.multiSelectContainer li:hover {
    background: transparent !important;
    color: #000 !important;
 
}

.form-check-input:focus {

    box-shadow: none !important;
}