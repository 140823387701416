@import "custom.scss";

.availabilityCalendar {
    width: 100%;
}


.calendarContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
 
.calendarMonth {
    flex: 0 0 calc(33.333% - 8px);
    margin-bottom: 16px;
    box-sizing: border-box;
}

.CalendarDay__selected_span {
    background: $primary !important;
    color: white;
}

.DayPickerRangeController .CalendarDay__selected_start,
.DayPickerRangeController .CalendarDay__selected_end {
    background: $primary !important;
    color: white;
}

.DayPicker,
.DayPicker_transitionContainer,
.CalendarMonthGrid {
    width: 100% !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover{
    // background: $primary !important;
    // border: 1px double #fff;
}

[aria-disabled="true"] {
    cursor: unset;
}

.CalendarDay{
   position: relative; 
}

.calendar_tooltipText {

    z-index: 1000;
    padding: 0px 10px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(235, 235, 235);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
    color: #000;
    width: 130px;
    line-height: 15px;
    padding: 2px 5px;
    z-index: 99999;
}

.no-hover:hover {
    /* Override the hover effect when the `no-hover` class is present. For example: */
    background-color: inherit !important;
}

.CalendarDay--blocked:hover {
    background-color: inherit; // adjust color as needed
}

.CalendarDay .calendar_tooltipText {
    display: none;
}

.CalendarDay:hover .calendar_tooltipText {
    display: block;
}

.booked{
    text-decoration: line-through;
}

.CalendarDay {
  //  border: none;
}

.DateRangePickerInput{
    display: flex;
}

 .DateInput {
     width: inherit;
 }

.disabledDay{
    color: #cacccd;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    pointer-events: none;
    cursor: default !important;
    &:hover{
        background-color: white;
        box-shadow: none;
    }
}

 

// .shortbreak{
//     background: blue;
// }   

// .changeover{
//     background: $primary;
// }

// .booked{
//     text-decoration: line-through;
// }