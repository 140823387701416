.scale-up {
  transition: all .3s ease-in-out;
}

.scale-up:hover {
  transform: scale(1.05);
}

.fade-out-black{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(0 0 0 / 53%) 100%);
}