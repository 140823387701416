.search-from input[type="text"],
.search-from select,
.search-from .field 
{
    border: 1px solid #C5A05F;
    font-size: 14px;
    padding: 11px 20px !important;
    min-height: 58px;
}
.SearchFormOpened .SearchWidget-bg {
    z-index: 9999;
}
.search-from button.search-form-btn {
    min-height: 60px;
}
.serch-form-field.dates {
    margin-left: -1px;
}
.serch-form-field .DateInput_input {
     border: 0px solid #fff !important;
     min-height: 58px !important;
}

.serch-form-field  .DateInput_1:first-child  {
    border-right: 1px solid #ccc !important;
}

.search-from .location input{
    min-height: inherit;
    padding-left: 8px !important;
}

.serch-form-field{
    margin-bottom: 15px;
    position: relative;
}

.location button {
    border: 1px solid #ccc;
    min-height: 58px;
    box-shadow: none;
    border-left: none;
}

@media screen and (max-width: 800px) {
    .location button {
        border: 1px solid #ccc !important;
        display: flex;
        width: 100%;
    }
    .serch-form-field.location {
        z-index: 999;
    }
    .serch-form-field.location button+div {
        width: 100%;
    }
    .css-ik6y5r {
        box-shadow: none;
    }
    .search-from input[type="text"],
    .search-from select,
    .search-from .field {
        width: 100%;
    }
    .DateInput{
        width: 50%;
        max-width:100%;
    }
    .DateInput input {
        width: 100%;
    }
    .DateRangePickerInput,
    .DateRangePicker {
        width: 100%;
    }
}

.search-from {
    font-family: 'Montserrat', sans-serif;
}

@media only screen and (min-width: 1000px) {
    .search-from.horizontal {
        display: flex;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 800px) {
            
           .location button {
               border: 1px solid #ccc !important;
               display: flex;
 
           }

       .search-from.horizontal input[type="text"],
       .search-from.horizontal select,
       .search-from.horizontal .field {
           width: 100%;
           max-width: 100%;
          
       }

       .DateInput {
           max-width: 100%;
           width: 100%;
       }

       .DateRangePickerInput {
           display: flex;
       }
}


 
@media screen and (min-width: 1200px) {
   .search-from.horizontal {
      display: flex;
      
   }
}

@media screen and (min-width: 1200px) {


   .search-from input[type="text"],
   .search-from select,
   .search-from .field {
       border-left: 0px;
   }
   .search-from.horizontal .serch-form-field:first-child {
       border-left: 1px solid #ccc;
   }
}
 
.search-from{
    padding-top: 15px;
    position: relative;
    z-index: 9;
}

.search-from {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
 
}

 
.vertical.search-from{
    display: block;
}

.vertical.search-from input[type="text"],
.vertical.search-from select,
.vertical.search-from .field {
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 11px 20px !important;
    min-height: 58px;
    width: 100%;
    margin-bottom: 15px;
}

.vertical.search-from .DateInput {
    max-width: 50%;
    width: 50%;
}
 

.search-from .DateRangePickerInput div:nth-child(3)>input {
    border-left: none;
}

.DateRangePicker {
    position: relative;
    display: block;
}

 .mobile-search-active .show-map-mobile {
     z-index: 1;
 }

  .mobile-search-active {
      overflow: hidden !important;
  }

  .mobile-search-active .propertyItem {
      position: relative;
      z-index: 1;
  }

  body.mobile-search-active div#mobileForm {
      z-index: 1;
  }

  .SearchFormOpened{
    overflow: hidden !important;
  }