.SocialNetworks a {
    display: inline-block;
    height: 23px;
    width: 23px;
    margin-left: 10px;
    color: #c5a05f;
}

.SocialNetworks a {
    color: #c5a05f !important;
}