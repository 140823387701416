.DateInput{
   // width: inherit !important;
}

.DayPicker_transitionContainer__verticalScrollable{
   max-height: 80%;
}

.BookingForm td{
   border: none !important;
}

.CalendarMonth_table{
   width: 100% !important;
}

.BookingForm .DayPicker_transitionContainer {
   width: 100% !important;
}

.BookingForm >div>div{
   width: 100% !important;
}
.BookingForm >div>div>div{
   width: 100% !important;
}

.BookingForm >div{
   width: 100% !important;
}

.BookingForm .CalendarMonthGrid_month__horizontal{
   width: 100% !important;
}

  .BookingForm .DayPickerNavigation {
     display: flex;
      width: 90%;
      position: absolute;
  }

  .DayPickerNavigation__vertical {
      width: 100% !important;
      display: block !important;
      width: 100% !important;
      margin: 0 !important;
   }

  td.CalendarDay__selected {
    border: 1px solid #C5A05F !important;
    color: #C5A05F !important;
   //  width: 100% !important;
   //  display: inline-flex;
   //  justify-content: center;
   //  align-items: center;
 }

 

 .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover{
   background: #e9e9e9 !important;
   color: black !important;
 }

 .CalendarMonth_verticalSpacing {
    border-collapse: separate;
    width: 100%;
}

.BookingForm .DayPicker_weekHeader_ul {
    display: flex;
    justify-content: space-around;

}
.BookingForm .DayPicker_weekHeader_ul li small{
    font-size: 15px;
    font-family: Montserrat, sans-serif;
}

.BookingForm .DayPicker_weekHeader_ul li{
    text-align: center;
}

.BookingForm .DayPicker_weekHeader{
    position: relative;
    margin: 0 !important;
    left: 0;
}

 
 .BookingForm .CalendarMonth_caption {
    padding-top: 0px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    
 }