 .main-app{
        min-height: 100%;
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-columns: 100%;
 }

 

 html,
 body,
 div#root {
     width: 100%;
     height: 100%;
 }

 body{
    overflow: scroll;
 }

 .CalendarMonth_caption {
  
     font-weight: 200 !important;
 }

 .ReactModal__Body--open .NavigationMenu {
    z-index: 9 !important;
}