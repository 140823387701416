.customClass{
    position: relative;
}
.customClass:before{
    content: "";
    display: inline-block;
    width: 100%;
    height: 1px;
    position: absolute;
    background: #f3f4f6;
    bottom: -1px;
    transform: translateX(-100%);
}