 

.location .searchWrapper {
    border-right: none;
    border-top: none;
    border-bottom: none;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxNyAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNzUyODQgMTguMzc2MUw3Ljc1Mjc4IDE4LjM3NjJMNy43NTc1NiAxOC4zODE2QzcuOTk2OTUgMTguNjUyOSA4LjMwMDQ1IDE4LjcgOC40NiAxOC43QzguNjA1NDQgMTguNyA4LjkyMTUzIDE4LjY2OTkgOS4xNjc4MiAxOC4zNzUzQzEwLjk3MDcgMTYuMjUwOSAxMi40MTgyIDE0LjI3MjYgMTMuNDE3MyAxMi41ODg2QzEzLjkxNjggMTEuNzQ2NSAxNC4zMDc1IDEwLjk3MjUgMTQuNTc0MiAxMC4yODY1QzE0LjgzOTIgOS42MDQ3NiAxNC45OSA4Ljk4OTE1IDE0Ljk5IDguNDdDMTQuOTkgNC44NzY3IDEyLjA2MzMgMS45NSA4LjQ3IDEuOTVDNC44NzY3IDEuOTUgMS45NSA0Ljg3NjcgMS45NSA4LjQ3QzEuOTUgOC45ODg4NyAyLjEwMDAzIDkuNjA0MyAyLjM2MzkxIDEwLjI4NkMyLjYyOTQzIDEwLjk3MiAzLjAxODUgMTEuNzQ1OSAzLjUxNjE5IDEyLjU4ODFDNC41MTE2MyAxNC4yNzI1IDUuOTU0NDggMTYuMjUxMiA3Ljc1Mjg0IDE4LjM3NjFaTTAuMzUgOC40N0MwLjM1IDMuOTkzMyAzLjk5MzMgMC4zNSA4LjQ3IDAuMzVDMTIuOTQ2NyAwLjM1IDE2LjU5IDMuOTkzMyAxNi41OSA4LjQ3QzE2LjU5IDkuOTI1NzUgMTUuNzgyNSAxMS44MDEyIDE0LjU3NCAxMy43NzQ3QzEzLjM3NTEgMTUuNzMyNiAxMS44MTgyIDE3LjcyOTMgMTAuMzkzNyAxOS40MTI5QzkuOTA5MTYgMTkuOTc2MiA5LjIxNTg5IDIwLjMgOC40NyAyMC4zQzcuNzI0MDEgMjAuMyA3LjAzMDY2IDE5Ljk3NjEgNi41NDYxIDE5LjQxMjZDNS4xMTY4MiAxNy43MjkzIDMuNTYgMTUuNzMyNyAyLjM2MjMxIDEzLjc3NDlDMS4xNTUyIDExLjgwMTYgMC4zNSA5LjkyNTk3IDAuMzUgOC40N1oiIGZpbGw9IiNDNUEwNUYiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMC43Ii8+CjxwYXRoIGQ9Ik04LjQ2OTk3IDExLjg3QzkuNDM2NDcgMTEuODcgMTAuMjIgMTEuMDg2NSAxMC4yMiAxMC4xMkMxMC4yMiA5LjE1MzUgOS40MzY0NyA4LjM3IDguNDY5OTcgOC4zN0M3LjUwMzQ3IDguMzcgNi43MTk5NyA5LjE1MzUgNi43MTk5NyAxMC4xMkM2LjcxOTk3IDExLjA4NjUgNy41MDM0NyAxMS44NyA4LjQ2OTk3IDExLjg3WiIgZmlsbD0iI0M1QTA1RiIvPgo8L3N2Zz4K);
    background-size: 18px;
    background-position: 10px 25px;
    background-repeat: no-repeat;
    padding-left: 28px ;
}

.where_field{

}

.Location {
    outline: none;
}

.location input {
    border: none !important;
}

.location .searchWrapper input::placeholder {
    color: black;
}

@media screen and (min-width: 1200px) {
    .location .searchWrapper {
        border: none !important;
    }
}

@media screen and (min-width: 1500px) {
    input#search_input {
        width: 290px;
    }
}

.location-buttons {
    display: flex;
    gap: 10px;
    overflow: auto;
    padding-bottom: 20px;
    white-space: nowrap;
}

.location-button {
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
 
}

.location-button.selected {
    background-color: #c5a05f;
    color: white;
}

.hidden-checkbox {
    opacity: 0;
    position: absolute;
    left: -9999px;
}

.location-button:hover:not(.selected) {
    background-color: #e0e0e0;
}