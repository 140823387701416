@import "custom.scss";

.navigation-dark a {
    color: #fff;
    text-decoration: none;
}

.showWishlistName {
     font-family: 'Montserrat', sans-serif;
}

.navigation-dark a:hover {
    color: #fff;
}

ul.menu-drop-down{
    z-index: 9999;
    min-width: 250px;
    top: 100%;
    left: 0;
}

.navigation-dark ul.menu-drop-down {
    background: $primary;

}

.navigation-light ul.menu-drop-down {
    background-color: #fff;
}

.Navigation{
    grid-template-columns: 800px auto;
}

.Navigation a{
    text-decoration: none;
}
.Navigation a:hover{
    text-decoration: none;
}


.navigation-light a{
   color:black; 
}

.navigation-light a:hover{
    color: black;
}




@media only screen and (min-width: 1025px) {
    .NavigationMenu  a:hover{
       // background-color: darken($primary, 5%)
    }
    .level1-link{
        padding: 7px 0px;
    }
    .level2-item-menu{
       display: block;
       position: relative;
    }
    .menu-drop-down-level-3{
        top: 0px;
        position: absolute;
        //background: $primary;
        left: 100%;
        min-width: 250px;
    }
   .level2-menu-link,
   .level3-menu-link {
           display: inline-block;
   }
}

@media (hover: hover) {
.level1-item-menu:hover .menu-drop-down-level-1 {
    height: auto;
    overflow: visible;
}
.level2-item-menu:hover .menu-drop-down-level-3 {
    height: auto;
}
 .hvr-underline-from-left {
     vertical-align: middle;
     -webkit-transform: perspective(1px) translateZ(0);
     transform: perspective(1px) translateZ(0);
     box-shadow: 0 0 1px rgb(0 0 0 / 0%);
     position: relative;

 }

 [class^="hvr-"] {
     cursor: pointer;
     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
 }

 .hvr-underline-from-left:before {
     content: "";
     position: absolute;
     z-index: -1;
     left: 0;
     right: 100%;
     bottom: 0;
     background: darken($primary, 15%);
     height: 4px;
     -webkit-transition-property: right;
     transition-property: right;
     -webkit-transition-duration: 0.3s;
     transition-duration: 0.3s;
     -webkit-transition-timing-function: ease-out;
     transition-timing-function: ease-out;
 }

 .hvr-underline-from-left:hover:before,
 .hvr-underline-from-left:focus:before,
 .hvr-underline-from-left:active:before {
     right: 0;
 }
}

