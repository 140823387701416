 

.Footer a:hover{
    text-decoration: none;
}

.Footer a{
    text-decoration: none;
    color: black;
}
 
.Footer h2{
    color: #c5a05f;
    font-weight: 200;
}

.SocialNetwor a{
    color: #c5a05f;
    font-size: 50px !important;
}

.mid-footer h2{
    fons-size: 28px;
    font-weight: 300;
}