.WishButton {
 
}

.WishButton svg{
    z-index: 2;
}

.WishButton a{
    color: primary;
}

.WishButton{
    position: relative;
}